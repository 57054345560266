const projects = [
  {
    type: 'all', 
    name: 'LED System for farmer deatil digitalization', 
    description: "This is a system i was a part of in Shangrilla Infomatics designed digitizing farmer's data of Nepal to be able to sell their products online.", 
    src: 'led_preview.png', 
    repository: '#', 
    site: 'http://led.chulachulimun.gov.np:8069/ne_NP', 
    technologies: ['Odoo', 'Python', 'JavaScript'],
  },
  {
    type: 'all', 
    name: "Ecommerce Site for RaShi's Collection", 
    description: "This is a full fledge ecommerce site including inventory management built for a boutique located in Sydney Australia.", 
    src: 'rashis.png', 
    repository: '#', 
    site: 'https://rashis.tribl.tech/', 
    technologies: ['Odoo', 'Python', 'JavaScript'],
  },
  {
    type: 'all', 
    name: "Ecommerce Site for Vastra Designers", 
    description: "This is a full fledge ecommerce site including inventory management built for a boutique located in Sydney Australia.", 
    src: 'vastra.png', 
    repository: '#', 
    site: 'https://vastra.tribl.tech/', 
    technologies: ['Odoo', 'Python', 'JavaScript'],
  },
  {
    type: 'all', 
    name: "Workshop Management System", 
    description: "It a project designed to help a workshop store customers data with their vehicle data to keep track of the vehicles service history.", 
    src: 'workshop.png', 
    repository: 'https://gitlab.com/sitaulasiddhartha2002/workshop-odoo', 
    site: 'https://workshop.tribl.tech/', 
    technologies: ['Odoo', 'Python', 'JavaScript'],
  },
  {
    type: 'front', 
    name: 'Path Finding Algorithm Visualiser', 
    description: 'A Frontend React App that visualises following algorithms:BFS, Genetic Algorithm, A* Algorithm, Dijkstras Algorithm', 
    src: 'path_algo.png', 
    repository: 'https://github.com/saurav188/path_algo_visualiser', 
    site: 'https://saurav188.github.io/path_algo_visualiser/', 
    technologies: ['React', 'JavaScript'],
  },
];

export default projects;