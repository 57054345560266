const experience = [
    {
        start: new Date("2023-05-16"), 
        end: new Date("2023-08-16"), 
        position: 'Intern', 
        company:'Shangrilla Infomatics',
        description:'In my Odoo developer internship at Shangrilla Infomatics, I designed and built systems to automate tasks. I collaborated effectively in a team to be offered to join the company as a Junior Python Developer. This experience honed my skills in Odoo development tools, module creation, and system customization.'
    },
    {
        start: new Date("2023-08-16"), 
        end: new Date("2024-04-01"), 
        position: 'Python Developer', 
        company:'Shangrilla Infomatics',
        description:"In my role as a Python System Engineer at Shangrila Infomatics, I developed a Python backend system integrated with Odoo for efficient storage and retrieval of farmer data. I successfully customized the Odoo framework to seamlessly handle Nepali dates. Additionally, I ensured smooth deployment of the system on a cloud VPS, guaranteeing scalability and accessibility. Furthermpre. I also played a key role in generating comprehensive reports for farmers and related entities."
    },
  ];
  
  export default experience;